jQuery(function () {
    var myOffside = offside("#mobileMenu", {
        slidingElementsSelector: "body",
        buttonsSelector: ".menu-toggle, .offcanvas-close, .offcanvas-overlay",
        beforeOpen: function () {
            $(".offcanvas-overlay").fadeIn();
        },
        beforeClose: function () {
            $(".offcanvas-overlay").fadeOut();
        },
    });

    $(".back-to-top").on("click", function () {
        $("html, body").animate({ scrollTop: 0 }, 2000);
    });

    if ($(window).width() > 1024) {
        if ($(window).scrollTop() > 0) {
            $("header").addClass("is-scrolling");
        } else {
            $("header").removeClass("is-scrolling");
        }

        $(window).on("scroll", function () {
            if ($(window).scrollTop() > 0) {
                $("header").addClass("is-scrolling");
            } else {
                $("header").removeClass("is-scrolling");
            }
        });

        $(".about-3 .item").on("mouseenter", function () {
            var src = $(this).data("src");
            $(".about-3 .background img").attr("src", src);
        });

        $(".who-we-are-slider .swiper-btn").css(
            "top",
            $(".who-we-are-slider .img img").height() / 2
        );
    } else {
        $(".header-bg").sticky({
            topSpacing: 0,
            zIndex: 999,
        });

        $(".has-sub .toggle").on("click", function () {
            $(this).next().slideToggle();
            $(this).toggleClass("fa-chevron-down fa-chevron-up");
        });
    }

    $(".change-url").on("change", function () {
        window.location.assign($(this).val());
    });

    $("body").on("change", ".ajaxfilter", function () {
        ProcessAjax($(this).val());
    });

    $(".count").countUp({
        time: 2000,
        delay: 10,
    });

    collapseComponent();
    newsCategorySlider();
    logoSlider();
    toggleViewMore();
});

function collapseComponent() {
    $(document).on("click", ".collapse-trigger", function () {
        $(this).next().slideToggle();
        $(this).toggleClass("active");
        return false;
    });
}

function ProcessAjax(pageurl) {
    //to get the ajax content and display in div with id 'content'
    $.ajax({
        url: pageurl,
        data: { isajax: true },
        success: function (data) {
            $(".ajaxresponse").html($(data).find(".ajaxresponse").html());
        },
    });

    //to change the browser URL to 'pageurl'
    if (pageurl != window.location) {
        window.history.pushState({ path: pageurl }, "", pageurl);
    }
}

function toggleViewMore() {
    $(".toggle-view-more-wrapper").each(function () {
        let step = 6;
        let _this = $(this);
        let size = _this.find(".toggle-item").length;
        _this.find(".toggle-item").slice(0, 6).show();
        if (size <= step) {
            _this.parent().find(".btn-view-more").hide();
        }
        $(".toggle-view-more-wrapper")
            .find(".btn-view-more")
            .on("click", function (e) {
                e.preventDefault();
                step = step + 6;
                _this.find(".toggle-item").slice(0, step).show();
                if (step >= size || size < step) {
                    _this.find(".btn-view-more").hide();
                }
            });
    });

    if ($("a.btn-view-more").length) {
        var height = $("footer").height();
        $(window).scroll(function () {
            if (
                $(window).scrollTop() + $(window).height() >=
                $(document).height() - height
            ) {
                $("a.btn-view-more").trigger("click");
            }
        });
    }
}

const menuMapping = new MappingListener({
    selector: ".header-bg-menu-wrapper",
    mobileWrapper: ".offcanvas-wrap",
    mobileMethod: "appendTo",
    desktopWrapper: ".header-bg-left",
    desktopMethod: "prependTo",
    breakpoint: 1024.98,
}).watch();

function newsCategorySlider() {
    $(".news-category-slider").each(function (index) {
        var $this = $(this);
        $this.addClass("news-category-slider-" + index);
        $this
            .find(".swiper-prev")
            .addClass("news-category-slider-prev-" + index);
        $this
            .find(".swiper-next")
            .addClass("news-category-slider-next-" + index);

        var newsCategorySlider = new Swiper(
            ".news-category-slider-" + index + " .swiper-container",
            {
                slidesPerView: 4,
                spaceBetween: 30,
                navigation: {
                    prevEl: ".news-category-slider-prev-" + index,
                    nextEl: ".news-category-slider-next-" + index,
                },
                autoplay: {
                    delay: 3000,
                },
                breakpoints: {
                    1440: {
                        slidesPerView: 4,
                    },
                    1280: {
                        slidesPerView: 3.5,
                        spaceBetween: 30,
                    },
                    1024: {
                        slidesPerView: 2.75,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 15,
                    },
                    576: {
                        slidesPerView: 1.25,
                        spaceBetween: 15,
                    },
                },
            }
        );
    });
}

const profileSlider = new Swiper(".profile-slider .swiper-container", {
    spaceBetween: 15,
    navigation: {
        prevEl: ".profile-slider .swiper-prev",
        nextEl: ".profile-slider .swiper-next",
    },
});

const bannerHomeSlider = new Swiper(".home-banner .swiper-container", {
    spaceBetween: 30,
    loop: true,
    effect: "fade",
    fadeEffect: { crossFade: true },
    autoplay: {
        delay: 4000,
    },
});

const whoWeAreSlider = new Swiper(".who-we-are-slider .swiper-container", {
    slidesPerView: 5,
    spaceBetween: 30,
    initialSlide: 1,
    autoplay: {
        delay: 3000,
    },
    loop: true,
    navigation: {
        prevEl: ".who-we-are-slider .swiper-prev",
        nextEl: ".who-we-are-slider .swiper-next",
    },
    pagination: {
        el: ".swiper-pagination",
        type: "progressbar",
    },
    breakpoints: {
        1280: {
            slidesPerView: 4,
            spaceBetween: 20,
            freeMode: true,
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 20,
            freeMode: true,
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 15,
            freeMode: true,
        },
        576: {
            slidesPerView: 1,
            spaceBetween: 15,
            freeMode: true,
        },
    },
});

const awardSlider = new Swiper(".award-slider .swiper-container", {
    slidesPerView: 5,
    spaceBetween: 30,
    autoplay: {
        delay: 3000,
    },
    loop: true,
    navigation: {
        prevEl: ".award-slider .swiper-prev",
        nextEl: ".award-slider .swiper-next",
    },
    breakpoints: {
        1024: {
            slidesPerView: 3,
            spaceBetween: 15,
            freeMode: true,
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 15,
            freeMode: true,
        },
        576: {
            slidesPerView: 1,
            spaceBetween: 15,
            freeMode: true,
        },
    },
});

const homeProjectSlider = new Swiper(".home-project-slider .swiper-container", {
    spaceBetween: 10,
    slidesPerView: 3,
    slidesPerColumn: 2,
    navigation: {
        prevEl: ".home-project-slider .swiper-prev",
        nextEl: ".home-project-slider .swiper-next",
    },
    breakpoints: {
        1024: {
            slidesPerView: 2.25,
        },
        768: {
            slidesPerView: 1.25,
        },
        576: {
            slidesPerView: 1.15,
        },
    },
});

function logoSlider() {
    $(".logo-slider").each(function (index) {
        var $this = $(this);
        $this.addClass("logo-slider-" + index);
        $this.find(".swiper-prev").addClass("logo-slider-prev-" + index);
        $this.find(".swiper-next").addClass("logo-slider-next-" + index);

        var logoSlider = new Swiper(
            ".logo-slider-" + index + " .swiper-container",
            {
                slidesPerView: 5,
                spaceBetween: 30,
                autoplay: {
                    delay: 3000,
                },
                navigation: {
                    prevEl: ".logo-slider-prev-" + index,
                    nextEl: ".logo-slider-next-" + index,
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 3.5,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 2.5,
                        spaceBetween: 15,
                    },
                    576: {
                        slidesPerView: 1.5,
                        spaceBetween: 15,
                    },
                },
            }
        );
    });
}

const newsSideSlider = new Swiper(".news-side-slider .swiper-container", {
    spaceBetween: 20,
    slidesPerView: 5,
    direction: "vertical",
    autoplay: {
        delay: 3000,
    },
    loop: true,
});
